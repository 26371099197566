import * as React from 'react'
import * as css from './Resources.module.css'
import SanityBlock from 'components/common/SanityBlock'
import slugify from 'slugify'

function slug(str) {
  return slugify(str, { lower: true })
}

export default function Resources({ title, resources }) {
  return (
    <section id="resources" className="padding container">
      {title && <h2 className={`${css.title} h2`}>{title}</h2>}

      {resources?.map(
        (resource, key) =>
          resource && (
            <article
              id={slug(resource?.title || '')}
              className={`${css.contents} richtext`}
              key={key}
            >
              {resource?.title && <h3 className="h3">{resource.title}</h3>}
              {resource?._rawDescription && (
                <SanityBlock body={resource?._rawDescription} />
              )}
            </article>
          ),
      )}
    </section>
  )
}
